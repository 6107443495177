<template>
    <div class="activity-log">
        <b-table :fields="fields" small :busy.sync="isBusy" striped :items="activityProvider" :per-page="perPage" :current-page="currentPage">
            <template v-slot:cell(category)="data">
                <b-badge variant="dark">{{data.item.category}}</b-badge>
            </template>
        </b-table>
        <b-pagination v-show="!isBusy" class="example" align="center" :hideGotoEndButtons="true" prevText="Previous" nextText="Next" size="sm" :limit="10" v-if="!isLoading" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
    </div>
</template>

<script>
    import Providers from '../../providers';

    export default {
        name: "ActivityLog",
        data() {
            return {
                isLoading: false,
                isBusy: false,
                perPage: 20,
                currentPage: 1,
                totalRows: 0,
                fields: [
                    {
                        key: 'name',
                        title: 'User'
                    },
                    {
                        key: 'category',
                        title: 'Category'
                    },
                    {
                        key: 'message',
                        title: 'Activity'
                    },
                    {
                        key: 'happenedAt',
                        title: 'Date'
                    }
                ]
            }
        },
        methods: {
            activityProvider(ctx, callback) {
                this.isBusy = true;
                Providers.admin.activityLog.activityLog(ctx.perPage, ctx.currentPage).then(response => {
                    let items = response.body.items;
                    items.map(item => {
                        if (item.happenedAt) {
                            item.happenedAt = this.moment(item.happenedAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
                        }
                    });
                    callback(items);
                    this.totalRows = response.body.totalItems;
                    this.isBusy = false;
                }).catch((resp) => {
                    this.$httpError('Failed to load activity', resp);
                }).then(() => {
                    this.isBusy = false;
                });
                return null
            }
        }
    }
</script>

<style scoped>

</style>